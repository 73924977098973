import React from "react";
import Layout from "../component/Layout";
import service from "../img/servicebg.png";
import "./home.css";
import { useNavigate } from "react-router-dom";
import ImageGallery from "../component/Gallery";

const Coprate = ({ photos }) => {
  const navigate = useNavigate();
  const images1 = [
    { src: "/img/corprate/idex/slide1.jpg", width: 4, height: 3 },
    { src: "/img/corprate//idex/slide2.jpg", width: 1, height: 1 },
    { src: "/img/corprate//idex/slide3.jpg", width: 3, height: 4 },
    { src: "/img/corprate/idex/slide4.jpg", width: 4, height: 3 },
    { src: "/img/corprate//idex/slide5.jpg", width: 1, height: 1 },
    { src: "/img/corprate//idex/slide6.jpg", width: 3, height: 4 },
    { src: "/img/corprate/indo us/slide1.jpg", width: 4, height: 3 },
    { src: "/img/corprate/indo us/slide2.jpg", width: 1, height: 1 },
    { src: "/img/corprate/indo us/slide3.jpg", width: 3, height: 4 },
    { src: "/img/corprate/indo us/slide4.jpg", width: 4, height: 3 },
    { src: "/img/corprate/indo us/slide5.jpg", width: 1, height: 1 },
    { src: "/img/corprate/indo us/slide6.jpg", width: 3, height: 4 },
    { src: "/img/corprate/indo us/slide7.jpg", width: 4, height: 3 },
    { src: "/img/corprate/Nupur/slide1.jpg", width: 1, height: 1 },
    { src: "/img/corprate/Nupur/slide2.jpg", width: 3, height: 4 },
    { src: "/img/corprate/Nupur/slide3.jpg", width: 4, height: 3 },
    { src: "/img/corprate/Nupur/slide4.jpg", width: 1, height: 1 },
    { src: "/img/corprate/Nupur/slide5.jpg", width: 3, height: 4 },
    { src: "/img/corprate/Nupur/slide6.jpg", width: 4, height: 3 },
    { src: "/img/corprate/Nupur/slide7.jpg", width: 1, height: 1 },
    { src: "/img/corprate/world/slide1.jpg", width: 3, height: 4 },
    { src: "/img/corprate/world/slide2.jpg", width: 4, height: 3 },
    { src: "/img/corprate/world/slide3.jpg", width: 1, height: 1 },
    { src: "/img/corprate/world/slide4.jpg", width: 3, height: 4 },
    { src: "/img/corprate/world/slide5.jpg", width: 4, height: 3 },
    { src: "/img/corprate/world/slide6.jpg", width: 1, height: 1 },
    { src: "/img/corprate/world/slide7.jpg", width: 3, height: 4 },
    { src: "/img/corprate/world/slide8.jpg", width: 4, height: 3 },
    { src: "/img/corprate/world/slide9.jpg", width: 1, height: 1 },
    { src: "/img/corprate/world/slide10.jpg", width: 3, height: 4 },
    { src: "/img/corprate/world/slide11.jpg", width: 4, height: 3 },
    { src: "/img/corprate/world/slide12.jpg", width: 1, height: 1 },
  ];

  return (
    <Layout>
      <div className="container-fluid margin px-5">
        <div className="row">
          <div className="col-md-4 col-sm-12 d-flex align-items-center">
            <h1 className="bg3h1">COPRATE PHOTO SHOOT</h1>
          </div>
          <div className="col-md-5 col-sm-12">
            <img
              src={service}
              alt="img"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div className="col-md-3 col-sm-12 d-flex align-items-center">
            <div>
              <h2 className="bg3h2">“Where Words Fail, Our Imagery Speaks”</h2>
              <p className="ptag">
                In our Coprate videography, we move away from the idea that
                videography is just a chronological compilation of wedding day
                events and toward the idea that videography is the art of
                telling a love story. From the song choice to the artistic
                selection of the scene order, everything comes together with
                modern wedding videography to tell stories of love, families,
                and celebrations.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row my-5">
          <div className="col-sm-12">
            <h1 className="bg3h2">Some Of Our Corprate Photo Shoots</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <ImageGallery photos={images1} />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div
          className="row text-center"
          style={{ background: "linear-gradient(to bottom,#f6f5f5, #e2e1e1)" }}
        >
          <div className="col-md-8 col-sm-12 text-center bg6">
            <h2 className="bg3h1">READY TO GET STARTED?</h2>
          </div>
          <div className="col-md-4 col-sm-12 d-flex align-items-center justify-content-center text-center">
            <button
              className="footerbtn"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Me
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Coprate;
