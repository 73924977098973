import React, { useRef } from "react";
import Layout from "../component/Layout";
import contact from "../img/contactbg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_aw1hnis",
        "template_d1di15g",
        form.current,
        "d6DZ3R4PJ5yjyzyZV"
      )
      .then(
        (result) => {
          toast.success(
            "Thankyou form your interest. we Will contact very soon"
          );
        },
        (error) => {
          toast.error("Failed to send message, please try again.");
        }
      );
  };

  return (
    <Layout>
      <div className="container-fluid pt-5 px-5">
        <div className="row pt-5">
          <div className="col-md-4 col-sm-12 d-flex align-items-center">
            <h1 className="bg3h1">Contact</h1>
          </div>
          <div className="col-md-5 col-sm-12">
            <img
              src={contact}
              alt="img"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div className="col-md-3 col-sm-12 d-flex align-items-center">
            <div>
              <h2 className="bg3h2">Get In Touch</h2>
              <p className="ptag">
                Welcome! We’re excited to work with you! Please fill out the
                form below, and our team will be in touch with the next steps!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2 col-sm-12 d-flex align-items-center justify-content-center">
            <div className="contactfontdiv text-center">
              <p>
                <FontAwesomeIcon className="contactfont" icon={faFacebookF} />
              </p>
              <p>
                <FontAwesomeIcon className="contactfont" icon={faTwitter} />
              </p>
              <p>
                <FontAwesomeIcon className="contactfont" icon={faWhatsapp} />
              </p>
              <p>
                <FontAwesomeIcon className="contactfont" icon={faYoutube} />
              </p>
              <p>
                <FontAwesomeIcon className="contactfont" icon={faInstagram} />
              </p>
            </div>
          </div>
          <div
            className="col-md-5 col-sm-12 px-5"
            style={{ backgroundColor: "#D2D6DA" }}
          >
            <div className="contactform">
              <ToastContainer />
              <h2
                className="bg3h2"
                style={{ fontSize: "30px", paddingBottom: "50px" }}
              >
                Send Message
              </h2>
              <form ref={form} onSubmit={sendEmail}>
                <input
                  type="text"
                  name="fullName"
                  placeholder="Name"
                  required
                  className="inp"
                />
                <input
                  type="number"
                  name="phone"
                  placeholder="Phone"
                  required
                  className="inp"
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  className="inp"
                />

                <select name="role" required className="inp">
                  <option value="" disabled selected>
                    Select Role
                  </option>
                  <option value="bride">Bride</option>
                  <option value="groom">Groom</option>
                  <option value="planner">Planner</option>
                  <option value="family">Family</option>
                  <option value="other">Other</option>
                </select>

                <select name="eventType" required className="inp">
                  <option value="" disabled selected>
                    Select Event Type
                  </option>
                  <option value="Wedding">Wedding</option>
                  <option value="Pre-Wedding">Pre-Wedding</option>
                  <option value="Engagement">Engagement</option>
                  <option value="Other">Other</option>
                </select>

                <input type="date" name="eventDate" required className="inp" />

                <textarea
                  name="message"
                  className="inp2"
                  required
                  placeholder="Message"
                />
                <button type="submit" className="contactbtn">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 d-flex justify-content-center align-items-center p-5">
            <div>
              <h1 className="bg3h1">Contact Me</h1>
              <p className="bg3h3 mt-4">Phone</p>
              <p className="bg3h2">+91 962-790-3849</p>
              <p className="bg3h3 mt-4">Email</p>
              <p className="bg3h2">studioshootbox@gmail.com</p>
              <p className="bg3h3 mt-4">Location</p>
              <p className="bg3h2">
                A 89, Shivalik Colony, Malviya Nagar, New Delhi, Delhi 110017
              </p>
            </div>
          </div>
          <div className="col-md-1 col-sm-12"></div>
        </div>
      </div>
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5413.136361520139!2d77.205306!3d28.530627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1f7f1757d1b%3A0x94b66bef6c362e36!2s89%2C%20A%20Block%20Main%20Rd%2C%20Block%20A%2C%20Shivalik%20Colony%2C%20Malviya%20Nagar%2C%20New%20Delhi%2C%20Delhi%20110017!5e1!3m2!1sen!2sin!4v1727073712451!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        ></iframe>
      </div>
    </Layout>
  );
};

export default Contact;
