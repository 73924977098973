import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../img/logo.png";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "./headfoot.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container-fluid p-3">
        <div className="row">
          <div className="col-sm-12 font">
            <FontAwesomeIcon className="footerfont" icon={faFacebookF} />
            <FontAwesomeIcon className="footerfont" icon={faInstagram} />
            <FontAwesomeIcon className="footerfont" icon={faTwitter} />
            <FontAwesomeIcon className="footerfont" icon={faYoutube} />
            <FontAwesomeIcon className="footerfont" icon={faWhatsapp} />
          </div>
        </div>
        <div className="row py-5">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <h6 style={{ fontSize: "15px", color: "#4A4B7C" }}>PHONE</h6>
            <p
              style={{
                fontSize: "25px",
                fontWeight: "700",
                color: "gray",
                paddingTop: "10px",
              }}
            >
              +91 962-790-3849
            </p>
            <h6
              style={{ fontSize: "15px", color: "#4A4B7C", paddingTop: "50px" }}
            >
              EMAIL
            </h6>
            <p
              style={{
                fontSize: "22px",
                fontWeight: "700",
                color: "gray",
                paddingTop: "10px",
              }}
            >
              studioshootbox@gmail.com
            </p>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 text-center">
            <h1 style={{ fontSize: "24px", color: "#4A4B7C" }}>
              INTERESTED IN MY SERVICES?
            </h1>
            <button
              className="footerbtn"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Me
            </button>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <img
              src={logo}
              alt="logo"
              style={{ height: "93px", width: "277px" }}
            />
            <p
              style={{
                fontSize: "17px",
                lineHeight: "24px",
                color: "#494949",
                textAlign: "center",
                paddingTop: "30px ",
              }}
            >
              Capturing timeless moments, one beautiful story at a time. Let
              Choice Production turn your special day into cherished memories.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p className="botmfooter1">Copyright © 2024 Photographer</p>
          </div>
          <div className="col-md-6 col-sm-12">
            <p className="botmfooter2">
              Powered by{" "}
              <a
                href="https://oshnbrand.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                oshnbrand.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
