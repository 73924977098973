import React from "react";
import Layout from "../component/Layout";
import service from "../img/servicebg.png";
import "./home.css";
import { useNavigate } from "react-router-dom";
import ImageGallery from "../component/Gallery";

const Wedding = ({ photos }) => {
  const navigate = useNavigate();
  const images1 = [
    { src: "/img/wedding/Aditya & Amanda/slide1.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Aditya & Amanda/slide2.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Aditya & Amanda/slide3.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Aditya & Amanda/slide4.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Aditya & Amanda/slide5.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Aditya & Amanda/slide6.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Aditya & Amanda/slide7.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Aditya & Amanda/slide8.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Aditya & Amanda/slide9.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Aditya & Amanda/slide10.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Aditya & Amanda/slide11.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Aditya & Amanda/slide12.jpg", width: 3, height: 4 },
  ];
  const images2 = [
    { src: "/img/wedding/Manoj & Deepika/slide1.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Manoj & Deepika/slide2.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Manoj & Deepika/slide3.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Manoj & Deepika/slide4.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Manoj & Deepika/slide5.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Manoj & Deepika/slide6.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Manoj & Deepika/slide7.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Manoj & Deepika/slide8.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Manoj & Deepika/slide9.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Manoj & Deepika/slide10.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Manoj & Deepika/slide11.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Manoj & Deepika/slide12.jpg", width: 3, height: 4 },
  ];
  const images3 = [
    { src: "/img/wedding/Swati & Samir/slide1.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Swati & Samir/slide2.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Swati & Samir/slide3.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Swati & Samir/slide4.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Swati & Samir/slide5.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Swati & Samir/slide6.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Swati & Samir/slide7.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Swati & Samir/slide8.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Swati & Samir/slide9.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Swati & Samir/slide10.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Swati & Samir/slide11.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Swati & Samir/slide12.jpg", width: 3, height: 4 },
  ];
  const images4 = [
    { src: "/img/wedding/Vivek & Anjali/slide1.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Vivek & Anjali/slide2.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Vivek & Anjali/slide3.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Vivek & Anjali/slide4.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Vivek & Anjali/slide5.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Vivek & Anjali/slide6.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Vivek & Anjali/slide7.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Vivek & Anjali/slide8.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Vivek & Anjali/slide9.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Vivek & Anjali/slide10.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Vivek & Anjali/slide11.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Vivek & Anjali/slide12.jpg", width: 3, height: 4 },
  ];
  return (
    <Layout>
      <div className="container-fluid margin px-5">
        <div className="row">
          <div className="col-md-4 col-sm-12 d-flex align-items-center">
            <h1 className="bg3h1">WEDDING SHOOT</h1>
          </div>
          <div className="col-md-5 col-sm-12">
            <img
              src={service}
              alt="img"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div className="col-md-3 col-sm-12 d-flex align-items-center">
            <div>
              <h2 className="bg3h2">“Where Words Fail, Our Imagery Speaks”</h2>
              <p className="ptag">
                Khushal and Teena is critically acclaimed for developing a
                unique style of wedding photography, deeply rooted in wedding
                photojournalism and influenced by fine-art and fashion
                photography. We pride ourselves on not just being photographers,
                but rather artists creating unique and expressive imagery.
              </p>
              <p className="ptag">
                As leaders and educators in the industry, we constantly
                experiment with new tools and techniques and blend various
                photography styles to challenge our creativity and tell
                compelling stories. What exactly does all of this mean? Well,
                let’s get into the specifics of what makes our style unique.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row my-5">
          <div className="col-sm-12">
            <h1 className="bg3h2">Aditya & Amanda</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <ImageGallery photos={images1} />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row my-5">
          <div className="col-sm-12">
            <h1 className="bg3h2">Manoj & Deepika</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <ImageGallery photos={images2} />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row my-5">
          <div className="col-sm-12">
            <h1 className="bg3h2">Swati & Samir</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <ImageGallery photos={images3} />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row my-5">
          <div className="col-sm-12">
            <h1 className="bg3h2">Vivek & Anjali</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <ImageGallery photos={images4} />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div
          className="row text-center"
          style={{ background: "linear-gradient(to bottom,#f6f5f5, #e2e1e1)" }}
        >
          <div className="col-md-8 col-sm-12 text-center bg6">
            <h2 className="bg3h1">READY TO GET STARTED?</h2>
          </div>
          <div className="col-md-4 col-sm-12 d-flex align-items-center justify-content-center text-center">
            <button
              className="footerbtn"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Me
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Wedding;
