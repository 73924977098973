import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider2.css"; // Assuming this is where your styles are defined

const CustomSlider = () => {
  const card = [
    {
      img: "/img/wedding/Vivek & Anjali/slide7.jpg",
      head: "Words from our happy clients",
      text: "I without doubt to say Choice Production is the Best Wedding Photographers in  New Delhi and recommend sunny & his team to any couple who is getting married, they were very professional & above all helped capture my wedding the way I had requested. Thank you to them for capturing beautiful moments of my wedding that I will cherish forever.",
      head2: "Vivek & Anjali",
    },
    {
      img: "/img/wedding/Swati & Samir/slide8.jpg",
      head: "Words from our happy clients",
      text: "I without doubt to say Choice Production is the Best Wedding Photographers in  New Delhi and recommend sunny & his team to any couple who is getting married, they were very professional & above all helped capture my wedding the way I had requested. Thank you to them for capturing beautiful moments of my wedding that I will cherish forever.",
      head2: "Swati & Samir",
    },
    {
      img: "/img/wedding/Aditya & Amanda/slide10.jpg",
      head: "Words from our happy clients",
      text: "I without doubt to say Choice Production is the Best Wedding Photographers in  New Delhi and recommend sunny & his team to any couple who is getting married, they were very professional & above all helped capture my wedding the way I had requested. Thank you to them for capturing beautiful moments of my wedding that I will cherish forever.",
      head2: "Aditya & Amanda",
    },
    {
      img: "/img/wedding/Manoj & Deepika/slide4.jpg",
      head: "Words from our happy clients",
      text: "I without doubt to say Choice Production is the Best Wedding Photographers in  New Delhi and recommend sunny & his team to any couple who is getting married, they were very professional & above all helped capture my wedding the way I had requested. Thank you to them for capturing beautiful moments of my wedding that I will cherish forever.",
      head2: "Manoj & Deepika",
    },
    {
      img: "/img/Nupur & Shashi/slide10.jpg",
      head: "Words from our happy clients",
      text: "I without doubt to say Choice Production is the Best Wedding Photographers in  New Delhi and recommend sunny & his team to any couple who is getting married, they were very professional & above all helped capture my wedding the way I had requested. Thank you to them for capturing beautiful moments of my wedding that I will cherish forever.",
      head2: "Nupur & Shashi",
    },
    {
      img: "/img/Upasana & Hemant/slide10.jpg",
      head: "Words from our happy clients",
      text: "I without doubt to say Choice Production is the Best Wedding Photographers in  New Delhi and recommend sunny & his team to any couple who is getting married, they were very professional & above all helped capture my wedding the way I had requested. Thank you to them for capturing beautiful moments of my wedding that I will cherish forever.",
      head2: "Upasana & Hemant",
    },
    // Additional slides...
  ];

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    dots: false,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  };

  return (
    <div className="custom-slider my-5">
      <Slider {...settings}>
        {card.map((e, index) => (
          <div key={index} className="slide-content">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <img
                    src={e.img}
                    alt="img"
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "10px",
                    }}
                  />
                </div>
                <div className="col-md-6 col-sm-12">
                  <h1 className="bg3h3 text-center py-4">{e.head}</h1>
                  <p className="ptag text-center p-3">{e.text}</p>
                  <h2 className="bg3h2 text-center mt-5">{e.head2}</h2>
                  {/* Arrows placed below the text */}
                  <div className="arrow-container">
                    {/* <PrevArrow />
                    <NextArrow /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

// const NextArrow = ({ onClick }) => {
//   return (
//     <button className="slick-next" onClick={onClick}>
//       <span className="slick-next-arrow">&gt;</span>
//     </button>
//   );
// };

// const PrevArrow = ({ onClick }) => {
//   return (
//     <button className="slick-prev" onClick={onClick}>
//       <span className="slick-prev-arrow">&lt;</span>
//     </button>
//   );
// };

export default CustomSlider;
