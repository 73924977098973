import React from "react";
import Layout from "../component/Layout";
import { useNavigate } from "react-router-dom";
import port from "../img/port.png";
import "./home.css";
import ImageGallery from "../component/Gallery";

const Portfolio = ({ photos }) => {
  const navigate = useNavigate();
  const images = [
    { src: "/img/black cap hoodie/slide1.jpg", width: 3, height: 4 },
    { src: "/img/black cap hoodie/slide2.jpg", width: 3, height: 4 },
    { src: "/img/black cap hoodie/slide3.jpg", width: 3, height: 4 },
    { src: "/img/black cap hoodie/slide4.jpg", width: 3, height: 4 },
    { src: "/img/black cap hoodie/slide5.jpg", width: 3, height: 4 },
    { src: "/img/black cap hoodie/slide6.jpg", width: 3, height: 4 },
    { src: "/img/black cap hoodie/slide7.jpg", width: 3, height: 4 },
    { src: "/img/black cap hoodie/slide8.jpg", width: 3, height: 4 },
    { src: "/img/black cap hoodie/slide9.jpg", width: 3, height: 4 },
    { src: "/img/black cap hoodie/slide10.jpg", width: 3, height: 4 },
    { src: "/img/black cap hoodie/slide11.jpg", width: 3, height: 4 },
    { src: "/img/black cap hoodie/slide12.jpg", width: 3, height: 4 },
  ];

  return (
    <Layout>
      <div className="container-fluid margin px-5">
        <div className="row ">
          <div className="col-md-4 col-sm-12 d-flex align-items-center">
            <h1 className="bg3h1">Portfolio</h1>
          </div>
          <div className="col-md-5 col-sm-12">
            <img
              src={port}
              alt="img"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div className="col-md-3 col-sm-12 d-flex align-items-center">
            <div>
              <h2 className="bg3h2">Photography Is A Way Of Feeling</h2>
              <p className="ptag">
                We have sampled dozens of album and bookmakers before finding
                the perfect match for our studio. Like our photography, we
                prefer our albums to be timeless.
              </p>
              <p className="ptag">
                Our high-quality, hand-crafted flush mount album is our most
                popular album. Its seamless flush mounted pages allow for
                beautiful panoramic spreads of your favorite photos. Its sturdy
                build and premium design make it an album that is as durable as
                it is beautiful. This classic album is characterized by a center
                fold with no split/cut and a storybook layout design that evokes
                tradition and elegance. Canvas photo covers, custom embossing,
                foiling, and several beautiful cover options give this album a
                personal touch that our clients love and appreciate
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5">
        <ImageGallery photos={images} />
      </div>
      <div className="container text-center">
        <div className="row">
          <div className="col-sm-12 p-5 bg7">
            <h1 className="bg3h1">Hire Me For Your Next Project</h1>
            <button
              className="footerbtn"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Portfolio;
