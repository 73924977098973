import React from "react";
import Layout from "../component/Layout";
import "./home.css";
import abt from "../img/abtback.png";
import SwiperComponent from "../component/Slider";

const About = () => {
  return (
    <Layout>
      <div className="container-fluid margin px-5">
        <div className="row">
          <div className="col-md-4 col-sm-12 d-flex align-items-center">
            <h1 className="bg3h1">CHOICE PRODUCTION PHOTOGRAPHY</h1>
          </div>
          <div className="col-md-5 col-sm-12">
            <img
              src={abt}
              alt="img"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div className="col-md-3 col-sm-12 d-flex align-items-center">
            <div>
              <p className="bg3h2">
                We have sampled dozens of album and bookmakers before finding
                the perfect match for our studio. Like our photography, we
                prefer our albums to be timeless.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 col-sm-12">
            <div className="bg5">
              <h1 className="bg3h1">WORK PROFILE</h1>
              <h3 className="bg3h3 px-5 pt-5">Lead Photographer 2015-2018</h3>
              <p className="ptag px-5">
                From 2015 to 2018, as a Lead Photographer, I spearheaded
                high-profile photoshoots, overseeing creative direction and
                ensuring quality across various projects. Managed a team of
                junior photographers, coordinated with clients, and delivered
                exceptional results in commercial, event, and portrait
                photography. Elevated brand visuals through innovative
                techniques and meticulous attention to detail.
              </p>
              <h3 className="bg3h3 px-5 pt-5">Photo Journalist 2011-2015</h3>
              <p className="ptag px-5">
                From 2011 to 2015, photojournalists played a pivotal role in
                capturing major global events such as the Arab Spring, the
                Syrian Civil War, and Occupy Wall Street protests. Their images
                brought to light the human cost of conflict, social movements,
                and natural disasters, often working under dangerous conditions.
                This period saw a rise in digital media, making real-time
                reporting and visual storytelling more accessible to the public.
              </p>
              <h3 className="bg3h3 px-5 pt-5">Intern Photographer 2009-2011</h3>
              <p className="ptag px-5">
                As an Intern Photographer from 2009 to 2011, I assisted in
                capturing and editing visual content for various projects,
                gaining hands-on experience in photography techniques.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-12"></div>
          <div className="col-md-4 col-sm-12 p-5">
            <h2 className="bg3h2">
              My Fascination With Creating Imagery Began
            </h2>
            <p className="ptag">
              My fascination with creating imagery stems from a deep passion for
              visual storytelling and the endless possibilities that come with
              it. Whether it’s through photography, digital art, or video
              production, I am constantly captivated by the way images can evoke
              emotions, tell intricate stories, and communicate ideas that words
              sometimes cannot.
            </p>
            <p className="ptag">
              Through Choice Productions, I have been able to channel this
              passion into a platform that prioritizes creativity and
              innovation. Working with talented teams, we bring concepts to life
              by blending artistry with technical expertise, always striving to
              push the boundaries of what can be achieved visually. The
              collaborative process has not only honed my skills but also
              expanded my understanding of how powerful imagery can shape
              perspectives and experiences. With each project, I feel more
              inspired to continue crafting visuals that not only look beautiful
              but also resonate deeply with viewers.
            </p>
            <div className="mt-5">
              <SwiperComponent />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
