import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper/modules";
import "swiper/css/bundle";
import "./slider.css";

const SwiperComponent = () => {
  const images1 = [
    { src: "/img/wedding/Aditya & Amanda/slide1.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Aditya & Amanda/slide2.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Aditya & Amanda/slide3.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Aditya & Amanda/slide4.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Aditya & Amanda/slide5.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Aditya & Amanda/slide6.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Aditya & Amanda/slide7.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Aditya & Amanda/slide8.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Aditya & Amanda/slide9.jpg", width: 3, height: 4 },
    { src: "/img/wedding/Aditya & Amanda/slide10.jpg", width: 4, height: 3 },
    { src: "/img/wedding/Aditya & Amanda/slide11.jpg", width: 1, height: 1 },
    { src: "/img/wedding/Aditya & Amanda/slide12.jpg", width: 3, height: 4 },
  ];
  return (
    <div className="lunchbox">
      {/* Swiper container */}
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        loop={true}
        slidesPerView="auto"
        centeredSlides={true}
        spaceBetween={20}
        navigation={{
          nextEl: "#js-next1",
          prevEl: "#js-prev1",
        }}
        pagination={{ clickable: true }}
        grabCursor={true}
        keyboard={true}
      >
        {/* Slides */}
        {images1.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="product">
              <img className="photograph" src={slide.src} alt={slide.alt} />
            </div>
          </SwiperSlide>
        ))}

        {/* Add more SwiperSlide elements as needed */}
      </Swiper>

      {/* Navigation buttons */}
      <div id="js-prev1" className="swiper-button-prev"></div>
      <div id="js-next1" className="swiper-button-next"></div>
    </div>
  );
};

export default SwiperComponent;
