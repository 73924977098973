import React, { useState } from "react";
import Gallery from "react-photo-gallery"; // Keep this as is
import Carousel, { Modal, ModalGateway } from "react-images";
import "../pages/home.css";

const ImageGallery = ({ photos }) => {
  // Renamed custom component
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const gotoPrevious = () => {
    setCurrentImage((prevImage) => prevImage - 1);
  };

  const gotoNext = () => {
    setCurrentImage((prevImage) => prevImage + 1);
  };

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                src: x.src,
                width: x.width,
                height: x.height,
              }))}
              components={{
                FooterCaption: () => null, // Optional: customize footer
              }}
              onClickPrev={gotoPrevious}
              onClickNext={gotoNext}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default ImageGallery;
