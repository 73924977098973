import React from "react";
import Layout from "../component/Layout";
import "./home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper/modules";
import "swiper/css/bundle";
import "../component/slider.css";
import { useNavigate } from "react-router-dom";
import Carousel from "../component/AutoCarousel";
import abt from "../img/abtback.png";
import Slider2 from "../component/Slider2";
// import CarouselPage from "../component/Slider2";

const Homepage = () => {
  const card = [
    {
      number: "01",
      type: "Pre-Wedding",
      img: "/img/Manoj & Deepika/slide1.jpg",
      to: "/pre-wedding-photo-shoot",
    },
    {
      number: "02",
      type: "Wedding",
      img: "/img/wedding/Aditya & Amanda/slide1.jpg",
      to: "/wedding-photo-shoot",
    },
    {
      number: "03",
      type: "Corprate",
      img: "/img/corprate/indo us/slide5.jpg",
      to: "/corprate-photo-shoot",
    },
    {
      number: "04",
      type: "Portfolio",
      img: "/img/wedding/Manoj & Deepika/slide1.jpg",
      to: "/portfolio",
    },
  ];
  const images = [
    "/img/wedding/Aditya & Amanda/slide1.jpg",
    "/img/wedding/Manoj & Deepika/slide2.jpg",
    "/img/wedding/Swati & Samir/slide1.jpg",
    "/img/wedding/Vivek & Anjali/slide10.jpg",
  ];
  const images2 = [
    "/img/corprate/indo us/slide7.jpg",
    "/img/Upasana & Hemant/slide10.jpg",
    "/img/Manoj & Deepika/slide9.jpg",
    "/img/Nupur & Shashi/slide12.jpg",
  ];
  const navigate = useNavigate();
  const images1 = [
    { src: "/img/sldie1.webp", width: 6, height: 3 },
    { src: "/img/slide2.webp", width: 6, height: 3 },
    { src: "/img/slide3.webp", width: 6, height: 3 },
    { src: "/img/slide4.webp", width: 6, height: 3 },
    { src: "/img/slide5.webp", width: 6, height: 3 },
    { src: "/img/slide6.webp", width: 6, height: 3 },
  ];
  return (
    <Layout>
      <div className="container">
        {" "}
        <Carousel
          style={{ height: "100%", width: "100%", aspectRatio: "4/2" }}
          images={images}
        />
      </div>

      <h2 className="bg3h3 text-center pt-4">
        Creating Cinematic Style Wedding Videos & Photos{" "}
      </h2>

      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-md-6 col-sm-12 text-center">
            <div>
              {" "}
              <Carousel
                style={{ height: "100%", width: "100%", aspectRatio: "3/2" }}
                images={images2}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 d-flex align-items-center px-3">
            <div className="text-center" style={{ textAlign: "justify" }}>
              <h2 className="bg3h2">Your visual legacy begins here.</h2>
              <p style={{ textAlign: "justify" }}>
                Your visual legacy begins here. We are skilled storytellers,
                capturing couples through our lens with artful scenes and candid
                snapshots. Your search for the best wedding photographers in New
                Delhi, India ends at us. Inspired by life's diverse experiences,
                from intimate moments to thrilling adventures, we bring passion
                and creativity to every project. Our ability to distill the joy
                of marriage into art stems from intuition and empathy for our
                clients. Each frame we create is infused with the essence of
                love, joy, and the profound sense of commitment shared by the
                couples we have the privilege to document. We believe that every
                love story is exceptional and deserves to be immortalized in the
                most captivating and authentic manner possible. With genuine
                connections and a deep understanding of their journey, we create
                beautiful, authentic memories that will be cherished forever.
              </p>
            </div>
          </div>
        </div>
      </div>
      <h1 className="py-4 bg3h1 text-center">PRESS & PRAISE</h1>
      <div className="lunchbox">
        {/* Swiper container */}
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          loop={true}
          slidesPerView="auto"
          centeredSlides={true}
          spaceBetween={20}
          navigation={{
            nextEl: "#js-next1",
            prevEl: "#js-prev1",
          }}
          pagination={{ clickable: true }}
          grabCursor={true}
          keyboard={true}
        >
          {/* Slides */}
          {images1.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className="product">
                <img className="photograph" src={slide.src} alt={slide.alt} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1 col-sm-12"></div>
          <div className="col-md-7 col-sm-12">
            <h1 className="bg3h2 py-4">About Choice Productions Photography</h1>
            <p className="ptag">
              “Emotion is the true substance of wedding photography” and we as
              the Best Wedding Photographers in India strongly believe in the
              same. At our core, we believe that wedding photography is about
              more than just taking pictures. It’s about being privileged to
              witness your unique and beautiful love story unfold and capture
              its authentic essence. Our approach is centered around listening
              to your desires and aspirations, allowing us to craft stunning and
              meaningful memories of your dream wedding.
            </p>
            <p className="ptag">
              If you’re in search of the best wedding photographer in New Delhi,
              India, your quest ends right here. With our base in New Delhi,
              India, we have had the privilege of covering weddings in various
              countries. This experience has exposed us to a diverse range of
              individuals and cultures, including Hindu, Sikh, Muslim, and
              Christian weddings across Asia and Europe. Through this journey,
              we have gained a profound understanding of our client’s specific
              needs and desires.
            </p>
            <p className="ptag">
              The ultimate reward for us is witnessing the contentment that
              radiates from our clients’ faces. It is this fulfillment that
              truly satisfies our souls, knowing that we have captured the
              essence of their special day in a way that exceeds their
              expectations.
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <img
              src={abt}
              alt="img"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <h2 className="bg3h2 text-center py-4">Our Signature Work</h2>
        </div>
        <div
          className="row d-flex justify-content-center"
          style={{ border: "1px solid gray" }}
        >
          {card.map((e, index) => (
            <>
              <div
                onClick={() => {
                  navigate(e.to);
                }}
                className="col-lg-3 col-md-6 col-sm-12 py-5 px-3"
                style={{ border: "1px solid gray", cursor: "pointer" }}
                key={index}
              >
                <h1 className="bg3h3 text-center">
                  <span style={{ fontFamily: "monospace" }}>{e.number}</span>
                  {e.type}
                </h1>
                <img
                  style={{ height: "100%", width: "100%" }}
                  src={e.img}
                  alt="img"
                />
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="container-fluid">
        <Slider2 />
      </div>
      <div className="container-fluid homebg2">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h3 className="bg3h3 text-center">
              We are ready to capture every cherished moment of your special
              day.
            </h3>
            <button className="footerbtn">Contact Me</button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Homepage;
