import React, { useState, useEffect } from "react";
import "./carousel.css";

const Carousel = ({ images, style }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideSpeed = 5000;

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, slideSpeed);

    return () => clearInterval(interval);
  }, [currentSlide]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === images.length - 1 ? 0 : prevSlide + 1
    );
  };

  return (
    <div>
      <div className="slider margin">
        {/* Slides */}
        {images.map((img, index) => (
          <div
            className={`slide ${index === currentSlide ? "active-slide" : ""}`}
            key={index}
          >
            <div className="container">
              <img style={style} src={img} alt={`Slide ${index + 1}`} />
            </div>
          </div>
        ))}

        {/* Navigation */}
        {/* <div className="slider-nav">
          <ul className="slider-dots">
            {images.map((_, index) => (
              <li
                key={index}
                className={`dot ${index === currentSlide ? "active-dot" : ""}`}
                onClick={() => setCurrentSlide(index)}
              ></li>
            ))}
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default Carousel;
