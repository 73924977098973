import React, { useEffect, useState } from "react";
import "./headfoot.css";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";

const Header = () => {
  const [activeLink, setActiveLink] = useState("Home");

  const handleClick = (linkName) => {
    setActiveLink(linkName);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <nav className="navbar navbar-expand-lg mynav bg-transparent  w-100">
        <div className="container-fluid">
          <Link className="navbar-brand">
            <img src={logo} alt="logo" className="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 px-5">
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    activeLink === "Home" ? "active-link" : ""
                  }`}
                  onClick={() => handleClick("Home")}
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    activeLink === "About" ? "active-link" : ""
                  }`}
                  onClick={() => handleClick("About")}
                  to="/about-us"
                >
                  About
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className={`nav-link dropdown-toggle ${
                    activeLink === "Services" ? "active-link" : ""
                  }`}
                  to="#"
                  id="servicesDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => {
                    handleClick("Services");
                  }}
                >
                  Services
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="servicesDropdown"
                >
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/pre-wedding-photo-shoot"
                    >
                      Pre-Wedding Shoot
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/wedding-photo-shoot">
                      Wedding Shoot
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/corprate-photo-shoot">
                      Corprate Shoot
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    activeLink === "Portfolio" ? "active-link" : ""
                  }`}
                  onClick={() => handleClick("Portfolio")}
                  to="/portfolio"
                >
                  Portfolio
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    activeLink === "Contact" ? "active-link" : ""
                  }`}
                  onClick={() => handleClick("Contact")}
                  to="/contact-us"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
