import "./App.css";
import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import About from "./pages/About";
import Service from "./pages/Service";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import Wedding from "./pages/Wedding";
import Coprate from "./pages/Coprate";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/pre-wedding-photo-shoot" element={<Service />} />
      <Route path="/wedding-photo-shoot" element={<Wedding />} />
      <Route path="/corprate-photo-shoot" element={<Coprate />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/contact-us" element={<Contact />} />
    </Routes>
  );
}

export default App;
